import { DestroyRef, ElementRef, inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../../../apps/portal/src/environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { CookieName, CookiesService } from '../../../cookies-policy/src/lib/cookies.service';
import { CookieConsent, CookiesPolicyService } from '../../../cookies-policy/src/lib/cookies-policy.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HomeRestService } from '../../../../apps/portal/src/app/rest-api/home-rest.service';
import { ReplaySubject } from 'rxjs';
import { InternationalizationService } from '../../../../apps/portal/src/app/services/internationalization.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CookiebotService {
    private readonly _cookiesService = inject(CookiesService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _renderer: Renderer2 = inject(RendererFactory2).createRenderer(null, null);
    private readonly _document = inject(DOCUMENT);
    private readonly _cookiesPolicyService = inject(CookiesPolicyService);
    private readonly _homeRestService = inject(HomeRestService);
    private readonly _internationalizationService = inject(InternationalizationService);
    private _destroyRef = inject(DestroyRef);
    private cookiebot: any;
    private readonly _cookiebotUrl = 'https://consent.cookiebot.com';

    public static readonly inited$ = new BehaviorSubject<boolean>(false);
    public static readonly loaded$ = new ReplaySubject<boolean>(1);

    public initCookiebot() {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        CookiebotService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit: boolean) => {
            if (isInit) {
                return;
            }

            const script = this._renderer.createElement('script');
            script.id = 'Cookiebot';
            script.src = `${this._cookiebotUrl}/uc.js`;
            script.async = true;
            script.setAttribute('data-cbid', environment.cookiebotTrackerId);
            script.setAttribute('data-culture', this._internationalizationService.activeLanguage.toUpperCase());

            script.type = 'text/javascript';
            script.onload = () => {
                this._cookiebotLoaded();
            };

            this._renderer.appendChild(this._document.head, script);
        });

        CookiebotService.inited$.next(true);
        CookiebotService.inited$.complete();
    }

    public loadCookiesDeclaration(element: ElementRef) {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        const script = this._renderer.createElement('script');
        const url = `${this._cookiebotUrl}/${environment.cookiebotTrackerId}/cd.js`;
        const id = 'CookieDeclaration';

        script.src = url;
        script.id = id;
        script.setAttribute('data-culture', this._internationalizationService.activeLanguage.toUpperCase());
        this._renderer.appendChild(element.nativeElement, script);
    }

    public onCookiebotChange(accept: boolean) {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        this._cookiesPolicyService.shareAcceptCookiesPolicy(this.cookiebot?.consent);

        if (this.cookiebot?.changed) {
            this._setCustomCookieConsent();

            if (accept) {
                this._homeRestService.acceptCookiesPolicy().pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
            } else {
                this._homeRestService.rejectCookiesPolicy().pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
            }
        }
    }

    private _cookiebotLoaded() {
      if (!isPlatformBrowser(this._platformId)) {
        return;
      }

      this.cookiebot = (window as any).Cookiebot as any;
      CookiebotService.loaded$.next(true);
      CookiebotService.loaded$.complete();
      this._verifyCookiesConsent();
    }

    private _verifyCookiesConsent(): void {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        const cookiebotConsent: CookieConsent|undefined = this.cookiebot?.consent;
        const customCookieValue = this._cookiesService.getCookieValue(CookieName.CookieAgree);
        let customCookieConsent: CookieConsent|undefined;

        try { customCookieConsent = JSON.parse(customCookieValue) } catch(e) {}

        if (cookiebotConsent?.method) {
            // jest zgoda cookiebot, a nie ma naszego customowego cookiesa (ktos byl juz na blogu i zatwierdzil cookiebot)
            if (!customCookieConsent) {
                this._setCustomCookieConsent();
                return;
            }

            // scenariusz: user posiada zgodę cookiebot-a i z customowego cookiesa:
            // sprawdzamy czy się różnią, jeśli tak aktualizujemy zgodę w cookiebot
            if (
                customCookieConsent?.method &&
                (
                    (customCookieConsent.preferences !== cookiebotConsent.preferences) ||
                    (customCookieConsent.statistics !== cookiebotConsent.statistics) ||
                    (customCookieConsent.marketing !== cookiebotConsent.marketing)
                )
            ) {
                this._setCookiebotConsentFromJson(customCookieConsent);
            }
        } else {
            // scenariusz: user nie udzielił zgody cookiebotowi:
            // jeśli posiada zgodę z customowego cookiesa, to aktualizjemy zgodę w cookiebot
            if (customCookieConsent?.method) {
                this._setCookiebotConsentFromJson(customCookieConsent);
            }
        }
    }

    private _setCookiebotConsentFromJson(consent: CookieConsent) {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        this.cookiebot?.submitCustomConsent(consent.preferences, consent.statistics, consent.marketing, true);
    }

    private _setCustomCookieConsent(): void {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        const json = JSON.stringify(this.cookiebot?.consent);
        this._cookiesService.setCookieValue(CookieName.CookieAgree, json, {expires: expires, path: '/', domain: true})
    }
}
